export enum CategoryActionType {
    FETCH_INIT = 'CATEGORY/FETCH_INIT',
    FETCH_SUCCESS = 'CATEGORY/FETCH_SUCCESS',
    FETCH_CANCEL = 'CATEGORY/FETCH_CANCEL',
    FETCH_FAILED = 'CATEGORY/FETCH_FAILED',
    CREATE_INIT = 'CATEGORY/CREATE_INIT',
    CREATE_DONE = 'CATEGORY/CREATE_DONE',
    CREATE_FAILED = 'CATEGORY/CREATE_FAILED',
    UPDATE_INIT = 'CATEGORY/UPDATE_INIT',
    UPDATE_DONE = 'CATEGORY/UPDATE_DONE',
    UPDATE_FAILED = 'CATEGORY/UPDATE_FAILED',
    DELETE_INIT = 'CATEGORY/DELETE_INIT',
    DELETE_DONE = 'CATEGORY/DELETE_DONE',
    DELETE_FAILED = 'CATEGORY/DELETE_FAILED',
    USAGES_INIT = 'CATEGORY/USAGES_INIT',
    USAGES_DONE = 'CATEGORY/USAGES_DONE',
    USAGES_FAILED = 'CATEGORY/USAGES_FAILED',
    MOVE_INIT = 'CATEGORY/MOVE_INIT',
    MOVE_DONE = 'CATEGORY/MOVE_DONE',
    MOVE_FAILED = 'CATEGORY/MOVE_FAILED',
    REFRESH = 'CATEGORY/REFRESH',
    RESET = 'CATEGORY/RESET'
}
