import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const ReportSvg = (): JSX.Element => (
    <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 512.000000 512.000000"
        preserveAspectRatio="xMidYMid meet"
    >
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="currentColor" stroke="none">
            <path
                d="M105 5106 c-42 -18 -93 -79 -100 -118 -3 -18 -4 -999 -3 -2179 l3
-2146 29 -37 c16 -21 47 -47 70 -57 39 -18 86 -19 1053 -19 l1012 0 3 -213 3
-214 28 -42 c18 -27 44 -50 70 -62 40 -18 82 -19 1100 -19 l1058 0 24 25 c30
30 32 64 4 99 -20 25 -24 26 -120 26 l-99 0 0 90 0 90 365 0 366 0 -3 -87 -3
-88 -97 -5 c-112 -6 -132 -17 -132 -76 0 -27 7 -42 24 -56 21 -17 37 -19 136
-16 108 3 114 4 150 33 76 62 74 47 74 530 0 491 0 488 -81 542 l-43 28 -378
3 -378 3 0 425 c0 474 1 470 -68 522 -34 26 -40 27 -201 32 l-166 5 -5 355
c-3 243 -9 361 -17 375 -20 36 -69 75 -104 85 -18 6 -114 10 -211 10 l-177 0
-3 306 -3 306 -24 19 c-30 24 -72 24 -102 0 l-24 -19 -3 -306 -3 -306 -406 0
c-394 0 -408 -1 -451 -21 -26 -13 -54 -38 -70 -62 -26 -39 -27 -47 -31 -194
l-3 -153 -772 0 -773 0 -21 -23 c-29 -31 -29 -77 1 -105 l23 -22 772 0 772 0
0 -195 0 -195 -775 0 c-762 0 -775 0 -795 -20 -30 -30 -27 -83 6 -109 26 -21
33 -21 795 -21 l769 0 0 -565 0 -565 -1007 2 -1008 3 0 2130 0 2130 1485 0
1485 0 5 -330 c3 -181 9 -337 13 -347 5 -9 22 -22 39 -29 44 -18 80 -2 95 41
15 44 18 593 3 669 -13 67 -42 108 -94 132 -39 18 -99 19 -1546 18 -1222 0
-1511 -2 -1535 -13z m2486 -2334 c-25 -86 -111 -185 -194 -222 -77 -36 -77
-36 -77 103 0 82 4 127 12 135 8 8 54 12 139 12 l128 0 -8 -28z m643 -9 c3
-21 22 -71 41 -112 61 -126 187 -231 316 -264 l54 -14 3 -127 3 -126 -153 0
c-140 0 -156 -2 -193 -23 -56 -32 -85 -87 -85 -163 l0 -62 -62 27 c-55 23 -77
26 -173 26 -106 0 -113 -1 -188 -37 -310 -148 -358 -555 -92 -771 92 -73 147
-91 285 -92 104 0 120 3 173 27 l57 27 0 -465 0 -464 -233 0 -232 0 -23 73
c-52 166 -189 298 -353 340 l-54 14 -3 896 -2 896 42 11 c24 5 76 26 117 47
126 63 219 175 257 311 l17 62 237 0 237 0 7 -37z m414 -100 c2 -103 0 -133
-10 -133 -31 0 -121 53 -163 95 -42 42 -95 132 -95 163 0 10 30 12 133 10
l132 -3 3 -132z m442 -783 l0 -90 -360 0 -360 0 0 83 c0 46 3 87 7 90 3 4 165
7 360 7 l353 0 0 -90z m-980 -126 c30 -14 67 -37 83 -52 l27 -26 0 -202 0
-202 -41 -36 c-59 -50 -125 -70 -214 -64 -265 17 -380 338 -188 525 86 83 225
107 333 57z m980 -199 l0 -85 -360 0 -360 0 0 85 0 85 360 0 360 0 0 -85z m0
-325 l0 -90 -360 0 -360 0 0 90 0 90 360 0 360 0 0 -90z m0 -330 l0 -90 -360
0 -360 0 0 90 0 90 360 0 360 0 0 -90z m864 84 c13 -5 16 -23 16 -90 l0 -84
-365 0 -365 0 0 83 c0 46 3 87 7 90 8 9 686 9 707 1z m-864 -414 l0 -90 -360
0 -360 0 0 90 0 90 360 0 360 0 0 -90z m880 0 l0 -90 -365 0 -365 0 0 83 c0
46 3 87 7 90 3 4 168 7 365 7 l358 0 0 -90z m-2573 -169 c81 -37 169 -138 193
-220 l9 -31 -134 0 c-112 0 -134 2 -139 16 -3 9 -6 69 -6 135 0 135 0 135 77
100z m1693 -161 l0 -90 -357 2 -358 3 -3 88 -3 87 361 0 360 0 0 -90z"
            />
            <path
                d="M4540 4840 c-30 -30 -27 -83 6 -109 23 -18 41 -21 138 -21 l111 0
-367 -367 -368 -368 -207 207 c-115 113 -218 209 -230 213 -13 3 -34 0 -50 -9
-40 -21 -940 -926 -952 -956 -11 -31 8 -84 34 -94 55 -21 54 -21 512 436 l443
443 208 -208 c190 -190 210 -207 242 -207 32 0 62 28 437 402 l403 403 0 -117
c0 -114 1 -117 26 -137 35 -28 69 -26 99 4 25 24 25 24 25 238 0 202 -1 216
-21 241 l-20 26 -225 0 c-211 0 -225 -1 -244 -20z"
            />
            <path
                d="M891 4596 c-31 -17 -39 -38 -40 -96 -1 -45 -1 -45 -49 -63 -68 -23
-135 -86 -168 -155 -22 -46 -27 -71 -28 -132 0 -126 49 -197 184 -269 l60 -32
0 -226 0 -225 -37 7 c-21 3 -62 20 -90 36 -29 16 -64 29 -77 29 -33 0 -65 -29
-72 -67 -5 -26 -2 -36 19 -56 38 -35 133 -78 199 -91 l58 -11 0 -42 c0 -54 16
-88 47 -102 36 -16 51 -14 78 14 21 20 25 34 25 80 0 44 4 57 18 61 149 49
243 152 272 300 31 159 -56 305 -221 370 l-69 27 0 179 0 178 35 -14 c20 -9
45 -22 57 -31 36 -24 83 -19 107 11 28 36 26 66 -6 99 -30 30 -106 68 -160 80
-32 6 -33 8 -33 59 0 38 -5 57 -17 69 -26 23 -65 29 -92 13z m-43 -506 l-3
-70 -37 25 c-73 50 -73 153 0 224 l37 36 3 -72 c2 -40 2 -105 0 -143z m207
-323 c77 -43 107 -124 80 -213 -14 -49 -65 -109 -112 -133 l-23 -12 0 191 c0
104 3 190 8 190 4 0 25 -10 47 -23z"
            />
            <path
                d="M1656 4408 c-21 -30 -20 -71 3 -99 l19 -24 467 0 467 0 25 29 c27 31
25 66 -4 99 -15 16 -51 17 -489 17 l-473 0 -15 -22z"
            />
            <path
                d="M1683 3890 c-44 -18 -57 -86 -23 -120 19 -19 33 -20 308 -20 263 0
290 2 305 18 32 36 22 102 -18 120 -31 14 -538 16 -572 2z"
            />
            <path
                d="M596 1418 c-21 -30 -20 -71 3 -99 l19 -24 499 -3 c330 -2 510 0 534
8 58 16 75 83 33 125 -13 13 -87 15 -544 15 l-529 0 -15 -22z"
            />
        </g>
    </svg>
);

export const ReportIcon = (props: Omit<CustomIconComponentProps, 'ref' | 'width' | 'height' | 'fill'>): JSX.Element => {
    const { style, ...otherProps } = props;

    return (
        <Icon
            component={ReportSvg}
            style={{
                ...style
            }}
            {...otherProps}
        />
    );
};
