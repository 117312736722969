import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const WalletSvg = (): JSX.Element => (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 512.000000 512.000000">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="currentColor" stroke="none">
            <path
                d="M2333 5102 c-66 -23 -124 -62 -158 -104 -14 -18 -253 -463 -530 -988
l-504 -955 -173 -6 c-146 -4 -181 -8 -228 -27 -111 -44 -205 -146 -240 -259
-20 -64 -20 -90 -20 -1237 0 -1277 -2 -1222 56 -1321 53 -90 162 -171 264
-194 30 -7 589 -11 1655 -11 1312 0 1619 3 1660 14 108 29 185 84 246 179 62
95 69 148 69 511 l0 316 65 0 c75 0 116 17 133 55 9 19 12 145 12 461 l0 436
-29 29 c-27 27 -35 29 -105 29 l-76 0 0 314 c0 172 -5 338 -10 367 -25 132
-112 243 -238 302 -61 29 -77 32 -184 35 -65 2 -118 5 -118 7 0 1 90 173 200
381 111 208 210 405 220 436 25 73 25 119 0 202 -16 51 -32 78 -73 121 -48 51
-101 81 -747 421 -947 498 -942 496 -1027 501 -49 2 -82 -2 -120 -15z m822
-542 c911 -479 915 -482 939 -514 15 -19 21 -42 21 -74 -1 -42 -28 -97 -232
-482 l-230 -435 -132 -3 c-72 -1 -131 1 -131 5 0 4 59 118 130 254 72 136 130
258 130 271 -1 37 -21 64 -80 105 -107 75 -148 211 -100 333 46 117 44 119
-292 295 -319 167 -331 171 -383 112 -16 -18 -42 -50 -57 -70 -64 -84 -197
-111 -323 -66 -112 41 -90 70 -397 -512 -147 -277 -293 -555 -325 -616 l-58
-113 -132 0 c-73 0 -133 2 -133 5 0 10 952 1810 964 1822 26 27 72 44 111 40
28 -3 243 -111 710 -357z m-70 -422 l173 -91 -5 -97 c-8 -144 29 -255 120
-355 l44 -50 -131 -248 -130 -247 -649 0 -649 0 47 88 c26 48 148 280 272 515
217 411 227 428 251 422 56 -14 190 -6 252 15 69 23 152 73 189 114 14 14 28
26 33 26 5 0 87 -41 183 -92z m1047 -1323 c23 -17 54 -51 68 -75 l25 -45 3
-332 3 -333 -228 0 c-172 0 -243 -4 -287 -15 -188 -49 -327 -190 -371 -377
-63 -267 107 -539 376 -603 47 -11 123 -15 287 -15 l223 0 -3 -333 -3 -334
-30 -49 c-23 -37 -44 -56 -84 -76 l-55 -28 -1595 0 c-1083 0 -1608 3 -1634 11
-49 13 -105 63 -128 114 -18 38 -19 100 -19 1198 0 1268 -3 1195 58 1261 15
16 45 37 67 47 39 18 100 19 1662 16 l1621 -2 44 -30z m308 -1290 l0 -305
-324 0 c-373 0 -394 3 -478 78 -176 159 -124 435 100 517 28 10 114 14 370 14
l332 1 0 -305z"
            />
        </g>
    </svg>
);

export const WalletIcon = (props: Omit<CustomIconComponentProps, 'ref' | 'width' | 'height' | 'fill'>): JSX.Element => {
    const { style, ...otherProps } = props;

    return (
        <Icon
            component={WalletSvg}
            style={{
                ...style
            }}
            {...otherProps}
        />
    );
};
